<template>
    <div class="page formPage" id="collectionDetails">
        <div class="plateBox">
            <div class="fieldBox">
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">展厅名称：</div>
                        <div class="flex1">{{ruleForm.exhibitionName}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">位置：</div>
                        <div class="flex1">{{ruleForm.exhibitionLocation}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">所属展览：</div>
                        <div class="flex1">{{ruleForm.exhibitionManagementId}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">启用状态：</div>
                        <div class="flex1">{{ruleForm.enabledState == 1 ? '已启用' : '未启用'}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="flex_l_t">
                        <div class="field">排序：</div>
                        <div class="flex1">{{ruleForm.serialNumber}}</div>
                    </div>
                </div>
                <div class="item1">
                    <div class="xiaobiaot">
                        <div class="field">大图：</div>
                        <div class="flex1">
                            <el-image
                                    v-for="item in ruleForm.guidedBannerList" :key="item.id"
                                    style="width: 200px; margin-right: 20px"
                                    :src="item.imgUrl"
                                    :fit="'contain'"></el-image>
                        </div>
                    </div>
                </div>
                <div class="item1">
                    <div class="xiaobiaot">
                        <div class="field">小图：</div>
                        <div class="flex1">
                            <el-image
                                    v-for="item in ruleForm.guidedImgFiles" :key="item.id"
                                    style="width: 200px; margin-right: 20px"
                                    :src="item.imgUrl"
                                    :fit="'contain'"></el-image>
                        </div>
                    </div>
                </div>
                <div class="item1">
                    <div class="xiaobiaot">
                        <div class="field">简介：</div>
                        <div class="flex1" v-html="ruleForm.introduction"></div>
                    </div>
                </div>

                <div style="text-align: center">
                    <el-button size="small" type="primary" @click="returnaf">返回</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import XLSX from "xlsx/types";
    import pdf from "vue-pdf";
    import mammoth from "mammoth";

    export default {
        name: "collectionDetails",
        components: {pdf,},
        props: {
            viewData: String,
        },
        data() {
            return {
                ruleForm:{},
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                if (!this.$route.params.num) { //查看
                    let id = this.$route.query.id;
                    //获取文件详情
                    this.$axios(this.api.exhibitionHall.getById + id, {}, 'get').then(res => {
                        this.ruleForm = res.data;
                        this.ruleForm.exhibitionManagementId = res.data.exhibitionManagementName

                        // this.$axios(this.api.exhibitionHall.queryAll,{},'get').then(res=>{
                        //     if (res.status){
                        //         res.data.forEach(item=>{
                        //             if (item.id == this.ruleForm.exhibitionManagementId){
                        //                 this.ruleForm.exhibitionManagementId = item.exhibitionName
                        //                 console.log(this.ruleForm.exhibitionManagementId)
                        //             }
                        //         })
                        //     }
                        // })
                    })
                }
            },

            returnaf(){
                let pageNo = this.$route.query.pageNo
                let culturalName = this.$route.query.culturalName;
                console.log(pageNo)
                this.$router.push({
                    path: '/exhibitionManagement/exhibition/exhibition',
                    query:{
                        pageNo:pageNo,
                        culturalName:culturalName,
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .el-carousel >>> .el-carousel__container {
        height: 100% !important;
    }

    .el-carousel__item >>> .el-image {
        height: 100%;
    }

    .el-carousel__item {
        text-align: center;
    }

    .word-wrap, .xlsx-wrap {
        overflow: auto;
    }

    .word-wrap >>> img, .xlsx-wrap >>> table {
        width: 100% !important;
    }

    .xlsx-wrap >>> tr {
        border-bottom: 1px solid #DCDCDC;
    }

    .xlsx-wrap >>> td {
        padding: 6px;
        text-align: center;
    }

    .info .el-button--small {
        font-size: 34px;
    }

    .button-new-tag {
        margin-left: 5px;
    }

    .home .plateTit {
        font-size: 1.2rem;
        margin-bottom: 2rem;
    }

    .mainName {
        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    .home .plateTit .plateTitIcon {
        display: inline-block;
        width: 0.31rem;
        height: 1.75rem;
        background: #A82126;
        border-radius: 0.16rem;
        margin-right: 0.5rem;
    }

    .fieldBox .item {
        display: inline-block;
        width: 45%;
        margin-right: 1%;
        margin-bottom: 1.2rem;
        vertical-align: top;
        font-size: 1rem;
        word-break: break-all;
    }

    .fieldBox .item1 {
        display: inline-block;
        width: 80%;
        margin-right: 1%;
        margin-bottom: 1.2rem;
        vertical-align: top;
        font-size: 1rem;
        word-break: break-all;
    }


    .plateBox {
        overflow-y: auto;
        height: calc(100vh - 125px);
        padding: 50px;
        border-radius: 5px;
        background: #ffffff;
        /*box-shadow: 0px 0px 3px 0px #000000;*/
        margin-bottom: 10px;
    }

    .adfadf {
        margin: 0;
    }

    .flex_l_t {
        display: flex;
        align-items: center;
    }

    .infoBox {
        height: 500px;
    }

    .w100 {
        width: 100%;
    }

    .formPage {
        padding: 0 !important;
        background: transparent !important;
    }

    .mainInfo{
        position: relative;
    }

    .xiazai {
        bottom: 15px;
        right: 50px;
        position: absolute;
        font-size: 30px;
        color: #5e6d82;
        cursor: pointer;
    }

    .flex1{
        flex: 1;
    }
    .xiaobiaot{
        display: flex;
        justify-content: flex-start;
    }

    .gaunbi{
        position: relative;
    }

    .aofbuia{
        font-size: 18px;
        top: -10px;
        right: 0;
        position: absolute;
        cursor: pointer;
    }
</style>
